import { ItemType, PageId } from "core/entities";
import {
  useTrackMetric,
  useAppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import { useLogComponentMount } from "./useLogComponentMount";

export function usePageLogging<T extends ItemType>({
  itemType,
  itemId,
  componentName,
}: {
  itemType: T;
  itemId: T extends "Page" ? PageId : string;
  componentName: string;
}) {
  useLogComponentMount(itemType, itemId);
  const appInsights = useAppInsightsContext();
  useTrackMetric(appInsights, componentName);
}
