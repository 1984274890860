import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";

type LoadingProps = { textDisabled?: boolean } & BoxProps;

export const Loading = forwardRef<HTMLDivElement, LoadingProps>(
  ({ textDisabled = false, ...rest }, ref) => {
    const { t } = useTranslation();
    return (
      <Box
        data-testid="loading-component"
        ref={ref}
        sx={{
          width: "100%",
          height: "100%",
          display: "grid",
          placeItems: "center",
          ...rest.sx,
        }}
        {...rest}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {!textDisabled && (
            <Typography variant="h2" color="primary">
              {t("Next:Core:Loading")}
            </Typography>
          )}
          <CircularProgress color="primary" />
        </Stack>
      </Box>
    );
  }
);
Loading.displayName = "Loading";
