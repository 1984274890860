import Tooltip from "@mui/material/Tooltip";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useTranslation } from "react-i18next";
import { useToaster } from "core/hooks/useToaster";
import { ItemType, PageId } from "core/entities";
import IconButton from "@mui/material/IconButton";
import { useEffect, useRef, useState } from "react";
import { useFavorite } from "../hooks/useFavorite";

export interface FavoriteButtonProps<T extends ItemType> {
  itemType: T;
  itemId: T extends "Page" ? PageId : string;
  title: string;
}

export function FavoriteButton<T extends ItemType>({
  itemType,
  itemId,
  title,
}: FavoriteButtonProps<T>) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:FavoriteButton",
  });
  const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
  useEffect(
    () => () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    },
    []
  );
  const { successToast, errorToast } = useToaster();
  const { isFavorite, addToFavorites, removeFromFavorites } = useFavorite(
    itemType,
    itemId,
    title
  );
  const [tempMessage, setTempMessage] = useState<null | string>(null);
  const toastMessage = isFavorite ? t("toastRemove") : t("toastAdd");
  const tooltipTitle = isFavorite ? t("hoverRemove") : t("hoverAdd");

  async function handleClick() {
    try {
      if (!isFavorite) {
        setTempMessage(toastMessage);
        timeoutRef.current = setTimeout(() => {
          setTempMessage(null);
        }, 3000);
        await addToFavorites();
      } else {
        setTempMessage(toastMessage);
        timeoutRef.current = setTimeout(() => {
          setTempMessage(null);
        }, 3000);
        await removeFromFavorites();
      }
      successToast(toastMessage);
    } catch {
      errorToast(t("toastError"));
    }
  }
  return (
    <Tooltip title={tempMessage ?? tooltipTitle}>
      <IconButton sx={{ zIndex: 1 }} onClick={handleClick} color="primary">
        {isFavorite ? (
          <StarIcon fontSize="large" />
        ) : (
          <StarBorderIcon fontSize="large" />
        )}
      </IconButton>
    </Tooltip>
  );
}
