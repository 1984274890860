import { useBreakpoints } from "core/hooks/useBreakpoints";
import { ReactNode } from "react";
import Stack, { StackProps } from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { HomePageGridArea } from "../entities";

export interface GridAreaContainerProps {
  gridArea?: HomePageGridArea | undefined;
  children: ReactNode;
  title: string | ReactNode;
  tooltip?: string;
  RightSlot?: ReactNode;
  BottomRightSlot?: ReactNode;
  disableBottomSlot?: boolean;
  stackProps?: StackProps;
}

export function GridAreaContainer({
  gridArea,
  RightSlot,
  BottomRightSlot,
  title,
  children,
  tooltip,
  disableBottomSlot,
  stackProps,
}: GridAreaContainerProps) {
  const { isMobile } = useBreakpoints();
  return (
    <Stack gridArea={gridArea} {...stackProps}>
      <Stack
        minHeight="40px"
        direction="row"
        color={isMobile ? "primary.main" : "text.secondary"}
        mb={2}
        gap={1}
        overflow="hidden"
        justifyContent="space-between"
        alignItems="center"
      >
        {typeof title === "string" ? (
          <Typography
            variant={isMobile ? "h5" : "button"}
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {title}
            {tooltip && <InfoIcon fontSize="small" />}
          </Typography>
        ) : (
          title
        )}
        {RightSlot}
      </Stack>
      {children}
      {!disableBottomSlot && (
        <Stack direction="row" justifyContent="flex-end" height="40px" mt={1}>
          {BottomRightSlot}
        </Stack>
      )}
    </Stack>
  );
}
