import { ItemType, PageId } from "core/entities";
import { useRef, useEffect } from "react";
import { useUserLog } from "./useUserLog";

export function useLogComponentMount<T extends ItemType>(
  type: T,
  id: T extends "Page" ? PageId : string
) {
  const isFirstMountRef = useRef(true);
  const { addLog } = useUserLog();
  useEffect(() => {
    (async () => {
      if (isFirstMountRef.current) {
        isFirstMountRef.current = false;
        await addLog(type, id);
      }
    })();
  }, [addLog, id, type]);
}
